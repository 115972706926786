import React from 'react';
import { SERVICE_STAGING } from 'renderer/constants';
import { getDocumentReference } from 'renderer/utils';
import { onSnapshot } from '@firebase/firestore';
import { QUERY_CACHE } from 'renderer/queries';
import { ScheduleModel } from 'renderer/models';
import { Timestamp } from 'firebase/firestore';
// 초단위 (30초)
var UPDATE_THRESHOLD = 30;
export var DataUpdateChecker = function (props) {
    var handleSnapshot = function (snapshot) {
        var _a, _b;
        try {
            var newDataFromFirebase = snapshot.data();
            var now = Timestamp.now();
            // UPDATE_THRESHOLD초 전 데이터는 무시 (오래된 데이터 무시)
            if (!newDataFromFirebase || ((_a = newDataFromFirebase.updatedAt) === null || _a === void 0 ? void 0 : _a.seconds) <= now.seconds - UPDATE_THRESHOLD) {
                return;
            }
            if (newDataFromFirebase.type === 'schedule') {
                invalidQuery(['schedules'], newDataFromFirebase);
                if (((_b = newDataFromFirebase.data) === null || _b === void 0 ? void 0 : _b.type) === ScheduleModel.RESERVATION_TYPE.RESERVATION) {
                    invalidQuery(['pendingSchedules'], newDataFromFirebase);
                    invalidQuery(['schedulesByCustomerId'], newDataFromFirebase);
                }
            }
            else if (newDataFromFirebase.type === 'sale') {
            }
        }
        catch (err) {
            console.log('err', err);
        }
    };
    React.useEffect(function () {
        try {
            var docRef = getDocumentReference("lastUpdate-".concat(SERVICE_STAGING), "".concat(props.storeId));
            var unsubscribe_1 = onSnapshot(docRef, handleSnapshot, function (error) { });
            return function () { return unsubscribe_1(); };
        }
        catch (err) { }
    }, [props.storeId]);
    return React.createElement("div", null);
};
var invalidQuery = function (queryKey, newDataFromFirebase) {
    var _a, _b;
    var queryState = QUERY_CACHE.getQueriesData(queryKey) || [];
    var lastQueryData = (queryState === null || queryState === void 0 ? void 0 : queryState.pop()) || [];
    var _c = lastQueryData, _ = _c[0], prevItems = _c[1];
    var lastUpdatedItem = prevItems === null || prevItems === void 0 ? void 0 : prevItems.reduce(function (acc, item) {
        return acc.updatedAt > item.updatedAt ? acc : item;
    }, (_a = prevItems[0]) === null || _a === void 0 ? void 0 : _a.updatedAt);
    if (!lastUpdatedItem) {
        return false;
    }
    var lastItemTimestamp = Timestamp.fromDate(lastUpdatedItem === null || lastUpdatedItem === void 0 ? void 0 : lastUpdatedItem.updatedAt);
    if ((lastItemTimestamp === null || lastItemTimestamp === void 0 ? void 0 : lastItemTimestamp.seconds) < ((_b = newDataFromFirebase === null || newDataFromFirebase === void 0 ? void 0 : newDataFromFirebase.updatedAt) === null || _b === void 0 ? void 0 : _b.seconds)) {
        QUERY_CACHE.invalidateQueries(queryKey);
    }
};
