var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from 'styled-components';
import { Icon, KRWFomatWithoutWon, TextComponent } from 'renderer/components';
import { DATE_FORMAT, PATH_APP_STORE_SUBSCRIPTIONS } from 'renderer/constants';
import { COLORS } from 'renderer/styles';
import { isEmpty, useWindowSize } from 'renderer/utils';
import { ProductQuery, StoreCourseQuery, StorePaymentMethodQuery } from 'renderer/queries';
import { ChargeDrawer } from 'renderer/containers/app/NewStore/StorePointCenter';
import dayjs from 'dayjs';
import { Atoms } from 'renderer/stores';
import { useRecoilState } from 'recoil';
import { IMAGES } from 'renderer/assets';
import { Layout, CommonImages } from 'common-ui';
import Badge from 'antd/lib/badge';
export var SidebarTop = function (props) {
    var _a;
    var store = props.store;
    // const storeId = store.id;
    var subscription = store.subscription;
    // const availableTrial: any = StorePaymentMethodQuery.useAvailableTrial({ storeId }, { enabled: storeId });
    var _b = useRecoilState(Atoms.globalSettingModalState), _ = _b[0], openSettings = _b[1];
    var isPC = useWindowSize().isPC;
    var storePointQuery = StorePaymentMethodQuery.useStorePoint(store.id, { enabled: !!store.id });
    var handleClick = function (isPoint) {
        var url = "".concat(PATH_APP_STORE_SUBSCRIPTIONS).concat(isPoint ? '?tab=point' : '?tab=subscription');
        props.onClickMenu({ path: url });
    };
    var getLabel = function () {
        if (subscription) {
            return dayjs(subscription.expiredAt).format(DATE_FORMAT);
        }
        else {
            return '무료플랜';
        }
    };
    var _c = React.useState(false), visible = _c[0], setVisible = _c[1];
    var _d = React.useState(false), isWarning = _d[0], setIsWarning = _d[1];
    React.useEffect(function () {
        var _a, _b, _c;
        var openSeeting = function (payload) {
            var _a;
            if (dayjs((_a = props.store) === null || _a === void 0 ? void 0 : _a.createdAt).isAfter(dayjs().subtract(1, 'day'), 'day')) {
                openSettings(payload);
            }
        };
        if (props.store) {
            var store_1 = props.store;
            if (!((_a = store_1 === null || store_1 === void 0 ? void 0 : store_1.preference) === null || _a === void 0 ? void 0 : _a.operation)) {
                setIsWarning(true);
            }
            if (!((_b = store_1 === null || store_1 === void 0 ? void 0 : store_1.preference) === null || _b === void 0 ? void 0 : _b.shouldShowWelcomeQuestion)) {
                if (!((_c = store_1 === null || store_1 === void 0 ? void 0 : store_1.preference) === null || _c === void 0 ? void 0 : _c.operation)) {
                    openSeeting({ type: 'calendar' });
                    setIsWarning(true);
                }
                else {
                    // 상품이 없을 경우 제품 등록 모달 띄워줌
                    setTimeout(function () {
                        var _a;
                        var products = (_a = ProductQuery.getProductsByStoreIdFromCache(store_1.id)) === null || _a === void 0 ? void 0 : _a.products;
                        if (isEmpty(products)) {
                            openSeeting({ type: 'product' });
                            setIsWarning(true);
                        }
                    }, 5000);
                }
            }
        }
    }, [(_a = props.store) === null || _a === void 0 ? void 0 : _a.id]);
    // 운영시간이 없을 경우 환경설정 띄워 줌
    var PointAndSubscriptionRow = function (type) {
        var _a;
        var isSubscription = type === 'subscription';
        return (React.createElement(StoreInfoInline, { onClick: function () { return handleClick(!isSubscription); }, stretch: 1, align: "center" },
            isSubscription ? (subscription ? (React.createElement(StyledBasicPlan, null)) : (React.createElement(CommonImages.FreePlanIcon, null))) : (React.createElement("div", { style: { marginLeft: '2px', marginRight: '2px', marginTop: '5px' } }, React.createElement(CommonImages.PointIcon, null))),
            React.createElement(TextComponent, { children: isSubscription ? getLabel() : "".concat(KRWFomatWithoutWon({ value: ((_a = storePointQuery.data) === null || _a === void 0 ? void 0 : _a.amount) || 0 }), "P"), style: { marginLeft: '10px', marginBottom: '3px' }, type: "subtitle2" }),
            React.createElement(Icon, { name: "right-chevron", size: 15, style: { marginRight: '5px' } })));
    };
    return (React.createElement(SidebarTopWarpper, null,
        React.createElement(Layout.Inline, { align: "start", style: { marginBottom: '8px', width: '220px' } },
            React.createElement(Icon, { name: "home", size: 22 }),
            React.createElement(TextComponent, { children: "".concat(store.name), ellipsis: true, style: { width: '190px', marginLeft: '20px' }, type: "headline3" })),
        PointAndSubscriptionRow('subscription'),
        PointAndSubscriptionRow('point'),
        React.createElement(ClickableInline, { align: "center", style: { width: '220px' }, onClick: function () {
                openSettings({ type: isPC ? 'basic' : 'list' });
            } },
            isWarning ? React.createElement(StyledPointerImg, { src: IMAGES.icon_setting_error }) : React.createElement(Icon, { name: "settings", size: 22 }),
            React.createElement(TextComponent, { children: "\uD658\uACBD\uC124\uC815", ellipsis: true, style: { width: '190px', marginLeft: '22px' }, type: "subtitle1" })),
        React.createElement(ThinkofyouLectureMenu, { storeId: store === null || store === void 0 ? void 0 : store.id }),
        React.createElement(ChargeDrawer, { store: store, visible: visible, onClose: function () { return setVisible(false); } })));
};
var StyledBasicPlan = styled(CommonImages.BasicPlanIcon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  transform: scale(0.8);\n"], ["\n  transform: scale(0.8);\n"])));
var ClickableInline = styled(Layout.Inline)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  cursor: pointer;\n  width: 180px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  &:hover {\n    background-color: ", ";\n  }\n"], ["\n  cursor: pointer;\n  width: 180px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  &:hover {\n    background-color: ", ";\n  }\n"])), COLORS.gray7);
var StoreInfoInline = styled(ClickableInline)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  &:hover {\n    background-color: ", ";\n  }\n  height: 24px;\n  width: 140px;\n  margin-left: 30px;\n  padding-left: 10px;\n"], ["\n  &:hover {\n    background-color: ", ";\n  }\n  height: 24px;\n  width: 140px;\n  margin-left: 30px;\n  padding-left: 10px;\n"])), COLORS.gray7);
var StyledPointerImg = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
export var SidebarTopWarpper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 22px;\n  padding-bottom: 5px;\n"], ["\n  padding: 22px;\n  padding-bottom: 5px;\n"])));
var ThinkofyouLectureMenu = function (props) {
    var _a = useRecoilState(Atoms.courseDrawerState), courseDrawer = _a[0], setCourseDrawer = _a[1];
    var courses = StoreCourseQuery.useGetCourses({ storeId: props.storeId }).data;
    var hasNewCourses = courses === null || courses === void 0 ? void 0 : courses.some(function (course) { return !course.isCompleted; });
    return (React.createElement(ClickableInline, { align: "center", style: { width: '220px' }, onClick: function () {
            setCourseDrawer(__assign(__assign({}, courseDrawer), { status: 'on' }));
        } },
        React.createElement(Badge, { dot: hasNewCourses },
            React.createElement(Icon, { name: "award", size: 22 })),
        React.createElement(TextComponent, { children: "\uD305\uCEE4\uBDF0 \uC0AC\uC6A9\uBC95\uAD50\uC721", ellipsis: true, style: { width: '190px', marginLeft: '20px' }, type: "subtitle1" })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
